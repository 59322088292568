//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './AdminManagementAdminsFilters.vue';
import Extra from './AdminManagementAdminsExtra.vue';
import CreateModal from './AdminManagementAdminsCreateModal.vue';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: { Filters, Extra, CreateModal },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      createAdminModal: {
        show: false,
        loading: false,
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('adminManagementAdmins', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('adminManagementAdmins', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      createAdminAction: 'createAdmin',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getStatus(id) {
      const { ADMIN_STATUS_LIST } = constant.admin;
      return this.$utils.getCaption(ADMIN_STATUS_LIST, id);
    },

    async createAdmin(params) {
      this.createAdminModal.loading = true;
      this.setGeneralProgress(true);
      try {
        const response = await this.createAdminAction(params);
        const { tempPassword } = response.data;
        await this.loadDataAction();
        this.createAdminModal.show = false;
        this.setSuccessNotification('Administrator successfully created');
        this.confirmAction({
          title: 'Save a temporary admin password',
          text: tempPassword,
          hasCancelButton: false,
        });
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.createAdminModal.loading = false;
      this.setGeneralProgress(false);
    },
  },
};
