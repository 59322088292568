//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import AdminManagementLog from '~/views/AdminManagement/AdminManagementLog/AdminManagementLog.vue';
import AdminManagementAdmins from '~/views/AdminManagement/AdminManagementAdmins/AdminManagementAdmins.vue';

export default {
  components: {
    AdminManagementLog,
    AdminManagementAdmins,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadAdminManagementLog(),
      this.loadAdminManagementAdmins(),
      this.loadAdminNotificationsSettings(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('adminManagementLog', {
      loadAdminManagementLog: 'loadData',
    }),
    ...mapActions('adminManagementAdmins', {
      loadAdminManagementAdmins: 'loadData',
      loadAdminNotificationsSettings: 'loadAdminNotificationsSettings',
    }),
  },
};
