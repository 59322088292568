export default [
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Email',
    },
    cell: {
      type: 'text',
      value: (e) => e.email,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Reg date',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'dateCreated',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 48,
    header: {
      type: 'text',
      caption: 'Access',
    },
    cell: {
      type: 'slot',
      name: 'access',
    },
  },
];
