export default [
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Date',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'dateCreated',
    },
  },
  {
    width: 16,
    header: {
      type: 'text',
      caption: 'Admin',
    },
    cell: {
      type: 'slot',
      name: 'admin',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'IP',
    },
    cell: {
      type: 'slot',
      name: 'ip',
    },
  },
  {
    width: 14,
    header: {
      type: 'text',
      caption: 'Admin status',
    },
    cell: {
      type: 'slot',
      name: 'adminStatus',
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Action',
    },
    cell: {
      type: 'slot',
      name: 'action',
    },
  },
  {
    width: 24,
    header: {
      type: 'text',
      caption: 'Comment',
    },
    cell: {
      type: 'slot',
      name: 'comment',
    },
  },
];
