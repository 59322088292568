var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-management-log"},[_c('div',{staticClass:"admin-management-log__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"admin-management-log__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":""},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"dateCreated",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getTime(row.dateCreated))+" ")])]}},{key:"admin",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.admin.email)+" ")]}},{key:"adminStatus",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getStatus(row.admin.adminStatus))+" ")]}},{key:"ip",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ipAddress)+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('pre',{class:{
            'success--text': row.successful,
            'error--text': !row.successful,
          }},[_vm._v(_vm._s(row.method))])]}},{key:"comment",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.comment)+" ")]}},{key:"extra",fn:function(ref){
          var row = ref.row;
return [_c('div',[_c('v-row',[_c('v-col',[_c('pre',[_vm._v(_vm._s(row.data))])])],1),(row.errorCode)?_c('v-row',[_c('v-col',[_c('pre',{staticClass:"error--text"},[_vm._v(_vm._s(row.errorCode)+": "+_vm._s(row.errorMessage))])])],1):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }