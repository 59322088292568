//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';

export default {
  props: {
    show: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      email: '',
      status: 0,
    };
  },

  computed: {
    statusList() {
      return constant.admin.ADMIN_STATUS_LIST;
    },

    isPossibleApply() {
      const { email, loading } = this;
      return (
        !!email
        && !loading
      );
    },
  },

  watch: {
    show(value) {
      if (!value) {
        this.email = '';
        this.status = 0;
      }
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    apply() {
      const { email, status } = this;
      const payload = {
        Email: email,
        AdminStatus: status,
      };

      this.$emit('apply', payload);
    },
  },
};
