var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-management-admins"},[_c('div',{staticClass:"admin-management-admins__filters"},[_c('div',{staticClass:"d-flex mobile-wrap"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('v-btn',{staticClass:"mt-3 ml-lg-12",attrs:{"color":"success","height":"40px","outlined":""},on:{"click":function($event){_vm.createAdminModal.show = true}}},[_vm._v(" "+_vm._s(_vm.$t('Create admin'))+" ")])],1)]),_c('div',{staticClass:"admin-management-admins__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":""},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"dateCreated",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getTime(row.dateCreated))+" ")])]}},{key:"access",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getStatus(row.adminStatus))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.blocked)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Blocked'))+" ")]):_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")])]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('CreateModal',{attrs:{"show":_vm.createAdminModal.show,"loading":_vm.createAdminModal.loading},on:{"apply":_vm.createAdmin,"close":function($event){_vm.createAdminModal.show = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }